
import { Component, Vue } from "vue-property-decorator";
import { clone } from "ramda";

import { Auth, Classes } from "@/store/modules";

import Thumbnail from "@/components/Thumbnail.vue";
import FeaturedThumbnail from "@/components/FeaturedThumbnail.vue";
import ClassCarousel from '@/components/ClassCarousel.vue';
import { Meta } from "@/misc/decorators";
import { SortOptions } from "@/store/classes";

@Component({
  name: "Categories",
  components: {
    Thumbnail,
    FeaturedThumbnail,
    ClassCarousel
  },
})
export default class Home extends Vue {
  classes: any[] = [];
  categories: any[] = [];
  freeClasses: any[] = [];
  count = 0;
  error = "";

  searchInput = "";
  loading = false;

  searchBy = ''
  breadcrumbs: any[] = [
    { text: 'Home', to: '/' },
    { text: 'Classes', to: '/search' },
  ];
  sortOptions: SortOptions[] = ['Date', 'Price: Low to High', 'Price: High to Low', 'Avg. Teacher Ratings'];
  sortBy = 'Date';
  itemsPerPage = 12;
  pages = 0
  currentPage = 1;


  

  @Meta
  metaInfo() {
    return {
      title: 'Search | SkillToon - Master New Skills with Live Interactive Workshops',
      link: [
        { rel: 'canonical', href: 'https://skilltoon.com/search' }
      ],
      meta: [
        { property: 'og:url', content: 'https://skilltoon.com/search' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'Search | SkillToon - New Way of Teaching & Learning Skills' },
        { property: 'og:description', content: 'Start exploring classes to learn, create and acquire skills in your area of Interest at home. Explore today!' },
        { property: 'og:image', content: 'https://app-dev.skilltoon.com/category_hero.png' },
        { name: 'description', content: 'Start exploring classes to learn, create and acquire skills in your area of Interest at home. Explore today!' },
        { name: 'keywords', content: 'SkillToon classes' },
        { name: 'robots', content: 'index,follow' },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:url', content: 'https://skilltoon.com/search' },
        { property: 'twitter:title', content: 'Search | SkillToon - New Way of Teaching & Learning Skills' },
        { property: 'twitter:description', content: 'Start exploring classes to learn, create and acquire skills in your area of Interest at home. Explore today!' },
        { property: 'twitter:image', content: 'https://app-dev.skilltoon.com/category_hero.png' },
      ]
    }
  }

  get sortedBy() {
    return Classes.sortBy as SortOptions;
  }

  get loggedIn() {
    return Auth.loggedIn;
  }

  get user() {
    return Auth.user;
  }

  get featuredClass() {
    return this.classes.find((class_) => class_.featured);
  }

  async doLogout() {
    return Auth.openLogoutPage();
  }

  async search() {
    if(this.searchInput) {
      this.$router.replace({ name: 'search', query: { query: this.searchInput }});
    } else {
      this.$router.replace({ name: 'search' });
    }
    const { items } = await this.getClasses()
    this.classes = clone(items);
  }

  async mounted() {
    this.loading = true;
    this.searchInput = this.$route.query.query as string || '';
    await this.getAllClasses();
    try {
      const res = await this.$axios.get(`/categories-with-classes`);
      const filtered = res.data.filter((course: any) => course.path.length === 0 );
      this.categories = filtered
    } catch(err: any) {
      this.error = err.message;
    }
    this.loading = false;
  }

  goToClass(id: string) {
    this.$router.push(`/classes/${id}`);
  }

  async getClasses() {
    try {
      const queryParams = new URLSearchParams();
      queryParams.append('upcoming', 'true');
      if(this.searchInput !== '') {
        queryParams.append('query', this.searchInput)
      }
      if(this.sortBy === 'Date'){
        queryParams.append('sort', 'date');
        queryParams.append('sortDesc', 'false');
      }
      if(this.sortBy === 'Price: Low to High'){
        queryParams.append('sort', 'fee');
        queryParams.append('sortDesc', 'false');
      }
      if(this.sortBy === 'Price: High to Low'){
        queryParams.append('sort', 'fee');
        queryParams.append('sortDesc', 'true');
      }
      if(this.sortBy === 'Avg. Teacher Ratings'){
        queryParams.append('sort', 'rating');
        queryParams.append('sortDesc', 'true');
      }
      queryParams.append('limit', this.itemsPerPage.toString())

      if(this.currentPage > 1) {
        const offset = (this.currentPage - 1)*this.itemsPerPage
        queryParams.append('offset', offset.toString())
      }

      const res = await this.$axios.get(`/classes?${queryParams.toString()}`)
      const { items, count } = res.data;
      this.pages = Math.ceil(count / this.itemsPerPage)

      return {
        items: items && items.filter((course: any) => course.meetings_count === course.upcoming_meetings_count),
        count
      } as any;
    } catch (err: any) {
      this.error = err.message;
    }
  }

  async sortChanged() {
    Classes.updateSearchParams({sortBy: this.sortBy as SortOptions})
    const { items } = await this.getClasses()
    this.classes = clone(items);
  }

  async pageChanged() {
    const { items } = await this.getClasses();
    this.classes = clone(items)
  }

  async getAllClasses() {
    try {
      const { items } = await this.getClasses();
      this.classes = clone(items);
    } catch (err: any) {
      this.error = err.message;
    }
  }

}
